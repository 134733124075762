import React from 'react'
import SendIcon from "../../assets/images/icons/send-icon.png";
const NormalBtn = () => {
  return (
    <>
         <img src={SendIcon} alt="#" />
         Send
    </>
  )
}

export default NormalBtn